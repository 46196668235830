
import { Component, Prop, Vue } from 'vue-property-decorator';
import KpiWidget from '../common/KpiWidget.vue';
import { BendingTimeBetweenBendDataPerDevice } from '@/models/Charts/timeBetweenBendKpiWidgetGenerator';
import { ChartGenerator } from '@/models/Charts/abstract/chartGenerator';
import WidgetDefinition from '@/models/Charts/widgetDefinition';

@Component({
  components: {
    KpiWidget,
  },
})
export default class TimeBetweenBendsKpiWidget extends Vue {
  @Prop({ required: true })
  private data!: BendingTimeBetweenBendDataPerDevice[] | null;

  @Prop({ required: true })
  private generator!: ChartGenerator<BendingTimeBetweenBendDataPerDevice>;

  @Prop({ required: true })
  private widgetDefinition!: WidgetDefinition;

  @Prop({ required: true, type: Boolean })
  private loading!: boolean;

  @Prop({ required: true, type: Boolean })
  private isFetchingForTheFirstTime!: boolean;

  private mappingFunction(entry: BendingTimeBetweenBendDataPerDevice) {
    return entry.timeBetweenBend;
  }
}
